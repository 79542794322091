import React, { Component, Fragment } from "react"
import {
  EuiAccordion,
  EuiFlexItem,
  EuiFlexGroup,
  EuiHideFor,
  EuiListGroup,
  EuiListGroupItem,
  EuiSpacer,
  EuiTitle,
  EuiText,
  EuiTextColor} from '@elastic/eui'

import {
  ReactiveBase,
  DataSearch,
  DateRange,
  SelectedFilters,
  ReactiveList,
  SingleList,
  MultiList,
  ResultList,
} from "@appbaseio/reactivesearch"

import { _connectionString, _index_api } from './ElasticParam'

const { ResultListWrapper } = ReactiveList

class ReactiveSearch extends Component {
  render() {
    return (
      <Fragment>
        <ReactiveBase
          url={_connectionString}
          app={_index_api}
          theme={{
            colors: {
              primaryColor: "#bebebe",
              textColor: "#7e7e7e"
            },
          }}
        >
          <DataSearch
            componentId="q"
            placeholder="INSTAR Search Assistant"
            react={{
              and: [
                "chapter_filter",
                "get_permissions_filter",
                "set_permissions_filter",
                "series_filter",
                "DateSensor"
              ],
            }}
            dataField={["title", "tags", "abstract", "description"]}
            fieldWeights={[8, 10, 5, 3]}
            searchOperators={false}
            autosuggest={true}
            highlight={false}
            fuzziness={1}
            debounce={1000}
            showFilter={true}
            filterLabel="Filter"
            URLParams={false}
          />
          <SelectedFilters />
          <div style={{ display: 'flex', marginTop: 20 }}>
            <EuiHideFor sizes={['xs', 's', 'm']}>
              <div style={{ flexDirection: 'row' }}>

                  <SingleList
                    componentId="series_filter"
                    dataField="series.raw"
                    title="Serie"
                    size={5}
                    sortBy="count"
                    defaultValue="1440p"
                    showRadio={true}
                    showCount={true}
                    showSearch={false}
                    react={{
                      and: ["q"],
                    }}
                    showFilter={true}
                    filterLabel="Filter"
                    URLParams={true}
                    loader="Lade ..."
                  />

                  <MultiList
                    componentId="chapter_filter"
                    dataField="chapter.de.raw"
                    title="Menu"
                    size={50}
                    sortBy="count"
                    react={{
                      and: ["q"],
                    }}
                    showSearch={false}
                    URLParams={true}
                    loader="Lade ..."
                  />

                  <MultiList
                    componentId="get_permissions_filter"
                    dataField="permissions.get"
                    title="GET Berechtigungen"
                    size={10}
                    showSearch={false}
                    react={{
                      and: ["q"],
                    }}
                    showFilter={true}
                    filterLabel="Filter"
                    URLParams={true}
                    loader="Lade ..."
                  />

                  <MultiList
                    componentId="set_permissions_filter"
                    dataField="permissions.set"
                    title="SET Berechtigungen"
                    size={10}
                    showSearch={false}
                    react={{
                      and: ["q"],
                    }}
                    showFilter={true}
                    filterLabel="Filter"
                    URLParams={true}
                    loader="Lade ..."
                  />

                  <DateRange
                      componentId="DateSensor"
                      compoundClause="filter"
                      dataField="date_modified"
                      title="Änderungsdatum"
                      placeholder={{
                        start: '1970-01-01',
                        end: '2077-01-01',
                      }}
                      focused={true}
                      numberOfMonths={0}
                      queryFormat="date"
                      autoFocusEnd={true}
                      showClear={true}
                      showFilter={true}
                      filterLabel="Änderungsdatum"
                      URLParams={true}
                  />

              </div>
            </EuiHideFor>
            <ReactiveList
              from={0}
              react={{
                and: [
                  "q",
                  "chapter_filter",
                  "get_permissions_filter",
                  "set_permissions_filter",
                  "series_filter",
                  "DateSensor"
                ],
              }}
              componentId="SearchResult"
              dataField="series.raw"
              stream={true}
              pagination={true}
              paginationAt="bottom"
              size={11}
              loader="Lade Ergebnisse.."
              showResultStats={true}
              scrollOnChange={true}
              sortOptions={[
                
                {
                  label: "Menu",
                  dataField: "chapter.en.raw",
                  sortBy: "asc"
                },
                {
                  label: "Series",
                  dataField: "series.raw",
                  sortBy: "desc"
                },
                {
                  label: "Best Match",
                  dataField: "_score",
                  sortBy: "desc"
                },
                {
                  label: "Modified ASC",
                  dataField: "date_modified",
                  sortBy: "asc"
                },
                {
                  label: "Modified DESC",
                  dataField: "date_modified",
                  sortBy: "desc"
                },
                {
                  label: "Created ASC",
                  dataField: "date_created",
                  sortBy: "asc"
                },
                {
                  label: "Created DESC",
                  dataField: "date_created",
                  sortBy: "desc"
                }
              ]}
            >

              {({ data }) => (
                <div style={{ marginLeft: 20, padding: 10, maxWidth: 600 }}>
                  <ResultListWrapper>
                    {data.map((item) => (
                      <>
                      <EuiAccordion
                        id={item.title}
                        element="fieldset"
                        borders="horizontal"
                        buttonProps={{ paddingSize: 'm' }}
                        buttonContent={
                          (
                            <div>
                              <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
                          
                                <EuiFlexItem>
                                  <EuiTitle size="xs">
                                    <h3>{item.title}</h3>
                                  </EuiTitle>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                          
                              <EuiText size="s">
                                <p>
                                  <EuiTextColor color="subdued">
                                    {item.abstract.de}
                                  </EuiTextColor>
                                </p>
                              </EuiText>
                            </div>
                          )
                        }
                        paddingSize="l"
                      >
                      <div style={{ background: 'white', margin: '10px 0', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }} key={item._id}>
                        <ResultList href={item.link.de}>
                            <ResultList.Image src={item.imagesquare} style={{ minWidth: 125 }} />
                            <ResultList.Content>
                              <h4
                                dangerouslySetInnerHTML={{
                                  __html: item.cgi,
                                }}
                              ></h4>
                              <ResultList.Description
                                dangerouslySetInnerHTML={{
                                  __html: item.description.de
                                }}
                              />
                              <EuiSpacer />
                              <p><strong>Klicken Sie hier für weitere Informationen</strong></p>
                            </ResultList.Content>
                          </ResultList>
                            <EuiListGroup flush={true} maxWidth={560}>

                              { item.parameters.map((parameter, index) => (
                                <div key={index}>

                                  <EuiListGroupItem
                                    id="Id__1"
                                    iconType="function"
                                    isActive
                                    iconProps={{ color: 'default' }}
                                    label={ parameter.param }
                                    wrapText
                                  />

                                  <EuiListGroupItem
                                    id="Id__2"
                                    iconType="consoleApp"
                                    label={ parameter.cgi }
                                    wrapText
                                  />

                                  <EuiListGroupItem
                                    id="Id__3"
                                    iconType="gisApp"
                                    iconProps={{ color: 'default' }}
                                    label={ parameter.val }
                                    wrapText
                                  />

                                  <EuiListGroupItem
                                    id="Id__4"
                                    iconType="filebeatApp"
                                    iconProps={{ color: 'default' }}
                                    label={ parameter.description.de }
                                    wrapText
                                  />

                                  <EuiListGroupItem
                                    id="Id__5"
                                    iconType="graphApp"
                                    iconProps={{ color: 'default' }}
                                    label={ parameter.mqtt }
                                    wrapText
                                  />
                                  <EuiSpacer />
                                </div>
                              ))}

                          </EuiListGroup>
                      </div>
                      </EuiAccordion>
                      <EuiSpacer size="l" />
                      </>
                    ))}
                  </ResultListWrapper>
                </div>
              )}
            </ReactiveList>
          </div>
        </ReactiveBase>
      </Fragment>
    );
  }
}


export default ReactiveSearch
