import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VideoTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Video Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Video Auflösung', descriptionColumn: 'Wählen Sie hier den Videokanal aus, den Sie konfigurieren möchten. Je nachdem welchen Videokanal Sie gewählt haben, werden Ihnen hier unterschiedliche Werte zur Auswahl stehen. Nur der erste Kanal kann die volle 1440p HD Auflösung (2560x1440) der Kamera wiedergeben. Kanal 2 hat eine 1080p Auflösung (1920x1080) und Kanal 3 360p (640x360).' },
        { actionColumn: 'Bildwiederholrate (fps)', descriptionColumn: 'Bei dieser Einstellung handelt es sich um die gewünschte Bildwiederholungsrate des Videobildes. Beachten Sie bitte, dass der hier eingestellte Wert nur das theoretische Maximum ist. Die reale Framerate wird auch durch die Netzwerkanbindung und die Belichtungszeiten der Kamera (umso dunkler es wird, desto länger muss die Kamera den Sensor belichten, um ein einzelnes Videobild aufzunehmen) bestimmt. Die Framerate eignet sich daher am besten um ein oberes Limit für die Übertragung festzulegen, um Ihr Netzwerk nicht zu überlasten. Der Standardwert ist 25 fps und ist für die meisten Überwachungsaufgaben eine gute Kompromisslösung.' },
        { actionColumn: 'Videokomprimierung', descriptionColumn: 'Die Videokomprimierung verringert die Bandbreitenanforderungen beim Zugriff auf den Videostream Ihrer Kameras über das Internet oder ein instabiles WLAN-Signal. Je stärker die Videokomprimierung ist, desto weniger Bandbreite wird benötigt. Aber Sie werden mehr Komprimierungsartefakte im Videostream Ihrer Kamera sehen.' },
        { actionColumn: 'Video Bitrate', descriptionColumn: 'Die Videobitrate bestimmt wie viele Informationen in dem Videostream enthalten sein können. Ein hochstellen dieses Wertes vergrößert massive die Bandbreitenanforderungen und die Größe der von der Kamera aufgenommenen Videos. Eine signifikante Verbesserung des Videos ist jedoch meistens nur dann zu beobachten, wenn das Kamerabild sehr viele Details oder sehr viele unterschiedliche Farben beinhaltet. Die Erhöhung der Bitrate wird die Bandbreitenanforderungen für den Zugriff auf den Stream über das lokale Netzwerk und erhöht auch die Datei Größe von aufgezeichneten Videos. Ein signifikanter Vorteil in der Bildqualität wird nur erreicht, wenn das Video viele Details enthält. Für die meisten Überwachungsaufgaben empfiehlt es sich diesen Wert auf dem Standard (1280-2560kbps) zu belassen.' },
        { actionColumn: 'Variable Bitrate', descriptionColumn: 'Bei der Videokodierung können Sie vorgeben, ob die Kamera durchgehend die maximale Bitrate halten soll, oder ob die Bitrate automatisch runtergeregelt werden soll, wenn die Kamera die Datenmenge nicht benötigt. Beispielweise benötigt die Kamera i.d.R. eine wesentlich höhere Bitrate um das Farbbild bei Tage wiederzugeben, als es für das Nachtbild notwendig wäre. Die Dateigröße der Videoaufnahme, die Bandbreitenanforderung des Live Videostreams und Auslastung der Kamera kann durch die variable Bitrate signifikant runtergehen.' },
        { actionColumn: 'Video Codec', descriptionColumn: 'Wählen Sie den Video-Codec, der für den Live-Video-Stream und die Videoaufzeichnung für jede der 3 Videoquellen Ihrer Kamera verwendet wird.' },
        { actionColumn: 'Keyframe Intervall', descriptionColumn: 'Ein wichtiger Teil der oben erwähnten erweiterten Videokomprimierung, ist die Aufarbeitung in i und p Bilder (i-Frames &amp; p-Frames). Nur die i-Frames beinhalten alle Bildinformationen. Bei den p-Frames handelt es sich um sogenannte Delta-Frames, die nur die Informationen beinhalten, die sich seit dem letzten Frame geändert haben. Die p-Frames sind somit um einiges kleiner, als ein i-Frame und führen zu geringeren Datenmengen beim Übertragen des Videos. Das aus p-Frames erzeugte Videobild besitzt jedoch einen interpolierten Anteil, der zu einer Verschlechterung der Videoqualität führt. Umso größer das Intervall zwischen zwei Keyframes (i-Frames) ist, desto geringer sind die Bandbreitenanforderungen des Videostreams und desto schlechter wird die Videoqualität. Das Keyframe Intervall sollte so gewählt werden, dass Sie ca. einmal pro Sekunde ein i-Frame erhalten. Der Standardwert für das Intervall liegt bei 20 Frames - Sie erhalten also, bei einer Framerate von 15fps alle (20 Frames/15 Frames/s) = 1.33s ein Vollbild (i-Frame).' },
        { actionColumn: 'Video Plugin', descriptionColumn: 'Wählen Sie hier mit welchen Plugin das Video in der Weboberfläche wiedergegeben werden soll. Als Standard ist hier HTML5 Video gewählt.' },
        { actionColumn: 'MotionJPEG Video Mode', descriptionColumn: 'Ihre Kamera kann im neben dem h.264 kodierten Stream ihr Video auch über eine Abfolge von Einzelbildern versenden. Dieser Motion-JPEG Stream hat den Vorteil, dass er grundsätzlich ohne Verwendung eines Videoplugins im Webbrowser wiedergegeben werden kann. Der Nachteil des Modus ist es, dass die Komprimierung hier wesentlich schwächer ist und die Übertragung entsprechend mehr Bandbreite benötigt als beim h.264 Stream. Sie finden einen Schnellzugiff auf diese Funktion in der Video Menüleiste.' },
        { actionColumn: 'HTML5 & MJPEG Auflösung', descriptionColumn: 'Stellen Sie hier die Auflösung ein, die der MJPEG und HTML5 Videostream für die Darstellung in der Weboberfläche verwenden soll. Diese Einstellung beeinflusst nicht die Auflösung der aufgenommenen Alarm Videos - diese werden weiterhin vom ersten Videostream in voller 2k+ (WQHD) Auflösung angelegt.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VideoTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VideoTable)